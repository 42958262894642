<template>
    <div id="resource">
        <div class="banan">
            <img src="../../assets/resourceBanan.png" alt="">
            <img class="text" src="../../assets/resourceTextBg.png" alt="">
        </div>
        <div class="resourceContain" data-aos="fade-up" data-aos-once='true'>
            <h1>知识视频样例</h1>
            <div class="resourceNavbar" v-if="navbar.length>0">
                <div class="navbarItem"  :class="navbarIndex==index?'navbarActive':''" v-for="(item,index) in navbar" @click="changeNavbar(index)">
                    {{item.title}}
                </div>
            </div>
            <div class="resourceList" data-aos="fade-up" data-aos-once='true'>
                <div class="resourceItem"  data-aos="fade-up" data-aos-once='true' v-for="(item,index) in courseList" @click="showVideo(index)">
                    <div class="itemImg">
                        <img :src="item.cover" alt="">
                        <!-- <span class="number">共{{item.count}}集</span> -->
                    </div>
                    <h3>{{item.title}}</h3>
                    <p>{{item.desc}}</p>
                </div>
            </div>
            <div class="resourceBottom">
                OHHH~ 我是有底线的~
            </div>
        </div>
        <div class="mask" v-show="videoShow">
            <div class="maskContain">
                    <div class="openImg" @click="openVideo">
                    <img src="../../assets/openVideo.png" alt="">
                </div>
                <h3>{{videoTitle}}</h3>
                <div id="videoPlay"></div>
            </div>
            
        </div>
        <div class="maskTip" v-if="tip">
            <div class="tipText">
                {{maskTip}}
            </div>
        </div>
    </div>
</template>
<script>
import API from '@/utils/request.js';
import Hls from 'hls.js';
import DPlayer from 'dplayer';
export default {
    name:'resource',
    data(){
        return{
            navbarIndex:0,
            videoShow:false,
            courseList:[],
            navbar:[],
            tip:false,
            maskTip:'',
            videoTitle:'',
        }
        
    },
    created(){
        this.getCourseTag()
    },
    mounted(){
    },
    methods:{
        // 初始化视频
        initVideo(url){
            let that = this;
            this.videos = new DPlayer({
                container: document.getElementById('videoPlay'),
                video: {
                    url: url,
                    // type: 'customHls',
                    type: 'auto',
                    lang: 'zh-cn',
                    hotkey:true,
                    autoplay:true,
                    customType: {
                        customHls: function (video, player) {
                        const hls = new Hls()
                        hls.loadSource(video.src)
                        hls.attachMedia(video)
                        }
                    },
                    referer:'http://www.sprout-edu.com',
                },
                pluginOptions: {
                    hls: {
                        // hls config
                    },
                },
            
            })
            this.videos.on('error',function(){
                that.messageMask('该视频资源整理中...',3000)
            })
            this.videos.on('canplay', () => {
                this.videos.play()
            })
            setTimeout(() => {
                document.querySelector(".dplayer-menu").remove();
            }, 3000);
            
        },
        changeNavbar(n){
            if(n==this.navbarIndex) return false;
            this.navbarIndex = n;
            this.getCourseList()
        },
        //停止页面滚动
        stopMove(){
            let m = function(e){e.preventDefault();};
            document.body.style.overflow='hidden';
            document.addEventListener("touchmove",m,{ passive:false });//禁止页面滑动
        },
        //开启页面滚动
        Move(){
            let m =function(e){e.preventDefault();};
            document.body.style.overflow='';//出现滚动条
            document.removeEventListener("touchmove",m,{ passive:true });
        },
        showVideo(n){
            if(this.videoShow) return false;
            if(this.disbale) return false;
            if(this.courseList[n].play_url==""){
                this.messageMask('该课程暂无视频',1500)
                return false;
            }
            this.disbale = true;
            setTimeout(()=>{
                this.disbale = false;
            },1000)
            this.videoTitle = this.courseList[n].title
            let url =this.courseList[n].play_url
            // let url ='http://vjs.zencdn.net/v/oceans.mp4';
            this.initVideo(url)
            this.videoShow = true;
            this.stopMove()
        },
        messageMask(tip,time){
            if(this.disbale2) return false;
            this.maskTip = tip,
            this.tip = true;
            setTimeout(() => {
                this.tip = false;
                this.maskTip = '';
            }, time);
        },
        openVideo(){
            this.videoShow = false;
            this.disbale2=true;
            setTimeout(()=>{
                this.disbale2 = false;
            },1500)
            this.videos.destroy()
            this.Move()
        },
        getCourseTag(){
            API.courseTag({}).then(res=>{
                if(res.code==200){
                    console.log(res.data)
                    let result = res.data;
                    for(let i = result.length-1;i>=0;i--){
                        if(result[i].display==0){
                            result.splice(i,1)
                        }
                    }
                    this.navbar = result
                    this.getCourseList()
                }
                else{
                    this.navbar = []
                }
                
            })
        },
        getCourseList(){
            let formData = new FormData();
            formData.append('tag_id',this.navbar[this.navbarIndex].id)
            API.coursetList(formData).then(res=>{
                if(res.code==200){
                    this.courseList = res.data
                }
                else{
                    this.courseList = []
                }
                
            })
        },

    }
}
</script>
<style lang="scss">
#resource{
    width: 100%;
    background: #F4F7F9;
    .banan{
        width: 100%;
        height: auto;
        position: relative;
        img{
            width: 100%;
        }
        .text{
            position: absolute;
            top: 22.17%;
            left:8.91%;
            width: 38.59%;
            // height: auto;
        }
    }
    .resourceContain{
        width: 1200px;
        margin: 0 auto;
        padding:60px 0 124px;
        text-align: center;
        h1{
            display: inline-block;
            position: relative;
            font-size: 38px;
            font-weight: bold;
            color: #000000;
        }
        h1::before{
            position: absolute;
            top: 50%;
            left: -90px;
            display: block;
            content: '';
            background: url('../../assets/titleLeft.png') no-repeat;
            width: 74px;
            height: 3px;
            transform: translateY(50%);
        }
        h1::after{
            position: absolute;
            top: 50%;
            right: -90px;
            display: block;
            content: '';
            background: url('../../assets/titleRight.png') no-repeat;
            width: 74px;
            height: 3px;
            transform: translateY(50%);
        }
        .resourceNavbar{
            width: 100%;
            display: flex;
            align-items: center;
            margin-top: 60px;
            cursor: pointer;
            .navbarItem{
                width: 184px;
                height: 58px;
                background: #FFFFFF;
                border-radius: 32px;
                text-align: center;
                line-height: 58px;
                font-size: 20px;
                font-weight: 500;
                color: #000000;
                margin-right: 19px;
            }
            .navbarActive{
                background: #3BCD0D;
                color:#FFFFFF;
            }
        }
        .resourceList{
            width:100%;
            margin-top: 89px;
            display: flex;
            flex-wrap: wrap;
            .resourceItem{
                width: 272px;
                height: 240px;
                background: #FFFFFF;
                border-radius: 8px;
                overflow: hidden;
                margin: 0 37px 24px 0;
                text-align: left;
                transition: all 0.3s;
                cursor: pointer;
                .itemImg{
                    width: 272px;
                    height: 153px;
                    background: rgba(0, 0, 0, 0);
                    border-radius: 8px 8px 0px 0px;
                    position: relative;
                    img{
                        width: 100%;
                        height: 100%;
                        background: silver;
                    }
                    .number{
                        position: absolute;
                        bottom: 8px;
                        right:12px;
                        background: rgba(0, 0, 0, .5);
                        border-radius: 2px;
                        padding: 4px 9px;
                        font-size: 10px;
                        font-weight: 400;
                        color: #FFFFFF;
                    }
                }
                h3{
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 26px;
                    color: #000000;
                    margin-top: 9px;
                    padding: 0 16px;
                    box-sizing: border-box;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                p{
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19px;
                    color: #828997;
                    margin-top: 9px;
                    padding: 0 16px;
                    box-sizing: border-box;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .resourceItem:hover{
                box-shadow: 0px 0px 24px rgba(17, 56, 100, 0.1);
                transform: scale(1.05);
            }
            .resourceItem:nth-child(4n){
                margin: 0 0 24px 0;
            }
        }
        .resourceBottom{
            width:100%;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            color: #898E91;
            margin-top: 60px;
        }
    }
    .mask{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, .12);
        z-index: 10;
        .maskContain{
            width: 1144px;
            height: 700px;
            background: #FFFFFF;
            border-radius: 20px;
            position: relative;
            padding: 0 27px;
            box-sizing: border-box;
            h3{
                font-size: 24px;
                font-weight: 500;
                line-height: 41px;
                color: #000000;
                padding: 12px 0 10px;
            }
            .openImg{
                position: absolute;
                right: 27px;
                top:27px;
                width: 54px;
                height: 22px;
                cursor: pointer;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            #videoPlay{
                width: 1090px;
                height: 612px;
                background: #000000;
                border-radius: 20px;
                display: block;
                margin: 0 auto;
            }
        }
       
    }
    .maskTip{
        width:100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        z-index: 14;
        justify-content: center;
        background: rgba(0, 0, 0, .12);
        .tipText{
            padding: 30px;
            font-size: 32px;
            color: #000000;
            background-color: #FFFFFF;
            border-radius: 20px;
        }
    }
}
</style>